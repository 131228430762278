import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import TplEditorGroup from "./TplEditorGroup";
import { useTplEditorStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewGroup,
  changeTemplateDescription,
  changeTemplateGroup,
  changeTemplateName,
} from "redux/reducers/templateEditorSlice";
import React, { useMemo } from "react";
export interface TplEditorTemplateProps {
  isNew: boolean;
}

const TplEditorTemplate = (props: TplEditorTemplateProps) => {
  const { classes } = useTplEditorStyles();

  const dispatch = useDispatch();

  const templateName = useSelector(
    (state: any) => state.templateEditor.template.name
  );
  const templateDescription = useSelector(
    (state: any) => state.templateEditor.template.description
  );
  const templateId = useSelector(
    (state: any) => state.templateEditor.template.template_id
  );

  const templateGroupId = useSelector(
    (state: any) => state.templateEditor.template.group_id
  );
  const templateGroupList = useSelector(
    (state: any) => state.templateEditor.templateGroupList
  );

  const handleTemplateNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(changeTemplateName(e.target.value));
  };
  const handleTemplateDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(changeTemplateDescription(e.target.value));
  };
  const handleNewGroupClick = () => {
    dispatch(addNewGroup({}));
  };
  const handleGroupChange = (e: SelectChangeEvent) => {
    dispatch(changeTemplateGroup({ value: e.target.value }));
  };

  return (
    <Box component="form">
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container direction={"row"}>
            {!props.isNew && (
              <TextField
                disabled
                size="small"
                label="Template id"
                value={templateId}
                className={classes.editorInput}
              ></TextField>
            )}
            <FormControl size="small" className={classes.editorInput}>
              <InputLabel id="template-group-select-label">
                Template group
              </InputLabel>
              <Select
                labelId="template-group-select-label"
                value={templateGroupId || ""}
                label="Template group"
                onChange={handleGroupChange}
              >
                {templateGroupList.map((dt: any) => {
                  return (
                    <MenuItem key={dt.group_id} value={dt.group_id}>
                      {dt.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              required
              size="small"
              label="Template name"
              value={templateName}
              onChange={handleTemplateNameChange}
              className={classes.editorInput}
            ></TextField>
          </Grid>
          <Grid container direction={"row"}>
            <TextField
              multiline
              size="small"
              rows={4}
              label="Description"
              value={templateDescription}
              onChange={handleTemplateDescriptionChange}
              className={classes.editorInput}
            ></TextField>
          </Grid>
        </Grid>
        <Grid item mt={3}>
          <Typography variant="h5">Groups:</Typography>
        </Grid>

        <Grid item pl={2}>
          <TemplateGroupList isNew={props.isNew} />
        </Grid>
        <Grid
          container
          mt={4}
          mb={5}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Button
            variant="outlined"
            onClick={handleNewGroupClick}
            sx={{ width: "200px" }}
          >
            New group
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const TemplateGroupList = React.memo((props: any) => {
  const groupsLength = useSelector(
    (state: any) => state.templateEditor.template.groups?.length
  );
  const groups = useMemo(
    () => Array.from({ length: groupsLength }, (_, index) => index),
    [groupsLength]
  );
  return (
    <>
      {groups?.map?.((group: number) => {
        return (
          <TplEditorGroup
            isNew={props.isNew}
            key={group + "_group"}
            groupIndex={group}
          ></TplEditorGroup>
        );
      })}
    </>
  );
});

export default TplEditorTemplate;
