import {
  setCallConfig,
  setFormDataConfig,
  trackedApiCall,
} from "./base/baseapi";
import { ApiResponse } from "./base/models/apiresponse";

export const loadSurveyTemplatesOrganizationsSummary = async (
  token: string | undefined
) => {
  const config = setCallConfig(
    "api/surveytemplates/organizations/sumary",
    "GET",
    token
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const loadSurveyTemplateOrganizationsSummary = async (
  token: string | undefined,
  templateId: string | undefined
) => {
  const config = setCallConfig(
    `api/surveytemplate/${templateId}/organizations`,
    "GET",
    token
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const changeTemplateOrganizationsAssignment = async (
  token: string | undefined,
  templateId: string | undefined,
  body: any
) => {
  const config = setCallConfig(
    `api/surveytemplate/${templateId}/organizations/bulk`,
    "POST",
    token,
    body
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const getSimpleSurveyTemplatesList = async (
  token: string | undefined,
  organizationId: string | undefined
) => {
  const config = setCallConfig(
    organizationId
      ? `api/surveytemplates/list/simple/${organizationId}`
      : "api/surveytemplates/list/simple",
    "GET",
    token
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const getSimpleUnassignedSurveyTemplatesList = async (
  token: string | undefined
) => {
  const config = setCallConfig(
    "api/surveytemplates/list/simple/unassigned",
    "GET",
    token
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const getSurveyTemplateEditData = async (
  token: string | undefined,
  templateId: string | undefined
) => {
  const config = setCallConfig(
    `api/surveytemplate/${templateId}`,
    "GET",
    token
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const uploadSurveyItemInstructionAttachment = async (
  token: string | undefined,
  templateId: string | undefined,
  version: number | undefined,
  itemId: string | undefined,
  file: any,
  htmlKey: string | undefined
) => {
  const formData = new FormData();
  formData.append("file", file);
  if (htmlKey) formData.append("html_key", htmlKey);
  const config = setFormDataConfig(
    `api/surveyiteminstructionaattachment/${templateId}/${version}/${itemId}`,
    token,
    formData
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const deleteSurveyItemInstructionAttachment = async (
  token: string | undefined,
  attachmentId: string | undefined,
  version: number | undefined
) => {
  const config = setCallConfig(
    `api/surveyiteminstructionaattachment/${attachmentId}/${version}`,
    "DELETE",
    token
  );
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const getSurveyTemplateItemDataTypes = async (
  token: string | undefined
) => {
  const config = setCallConfig(`api/datatypes/list`, "GET", token);
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
export const saveOrEditSurveyTemplate = async (
  token: string | undefined,
  templateId: string | undefined,
  version: number | undefined,
  body: any
) => {
  let url = "api/surveytemplate";
  if (templateId) {
    url += `/${templateId}`;
  }
  if (version) {
    url += `/${version}`;
  }
  const config = setCallConfig(url, templateId ? "PUT" : "POST", token, body);
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};

export const getTemplateGroups = async (token: string | undefined) => {
  const config = setCallConfig(`api/surveytemplate/groups`, "GET", token);
  const { data } = (await trackedApiCall(config)) as ApiResponse;
  return data;
};
