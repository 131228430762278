import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import TplEditorItem from "./TplEditorItem";
import { useTplEditorStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState } from "react";
import {
  addNewItem,
  deleteGroup,
  setGroupName,
  setGroupSortOrder,
} from "redux/reducers/templateEditorSlice";
import { styled } from "@mui/material/styles";
import { DeleteOutline } from "@mui/icons-material";
import { Box } from "@mui/system";
import ConfirmDialog from "./ConfirmDialog";

export interface TplEditorGroupProps {
  isNew: boolean;
  groupIndex: number;
}
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(3),
  backgroundColor: "#fbfbfb",
}));

const TplEditorGroup = (props: TplEditorGroupProps) => {
  const dispatch = useDispatch();

  const tfProps = useMemo(() => {
    return {
      style: { backgroundColor: "white" },
    };
  }, []);
  const groupId = useSelector(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].group_id
  );
  const name = useSelector(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].name
  );
  const handleGroupNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      setGroupName({ value: e.target.value, groupIndex: props.groupIndex })
    );
  };
  const sortOrder = useSelector(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].sort_order
  );
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      setGroupSortOrder({ value: e.target.value, groupIndex: props.groupIndex })
    );
  };
  const [deleteVisible, setDeleteVisible] = useState(false);
  const showDeleteModal = () => setDeleteVisible(true);
  const hideDeleteModal = () => setDeleteVisible(false);
  const handleDeleteConfirm = () => {
    dispatch(deleteGroup({ groupIndex: props.groupIndex }));
    hideDeleteModal();
  };

  const { classes } = useTplEditorStyles();
  return (
    <Item>
      <Box display="flex" flexDirection={"row"} flex={1}>
        <Typography variant="h6" gutterBottom m={1.2} flex={1}>
          {name}
        </Typography>
        <IconButton size="small" onClick={showDeleteModal}>
          <DeleteOutline />
        </IconButton>
      </Box>
      <Grid container direction={"column"}>
        <Grid item>
          <Grid container direction={"row"}>
            {!props.isNew && (
              <TextField
                disabled
                size="small"
                label="Group id"
                value={groupId}
                className={classes.editorInput}
                InputProps={tfProps}
              ></TextField>
            )}
            <TextField
              type="number"
              size="small"
              label="Sort order"
              value={sortOrder}
              onChange={handleSortOrderChange}
              className={classes.editorInputSmall}
              InputProps={tfProps}
            ></TextField>
            <TextField
              required
              size="small"
              label="Group name"
              value={name}
              onChange={handleGroupNameChange}
              className={classes.editorInput}
              InputProps={tfProps}
            ></TextField>

            {/* create a button to add a new item */}
          </Grid>
        </Grid>
        <Grid item m={2}>
          <Typography sx={{ fontWeight: "bold" }}>Items:</Typography>
        </Grid>
        <Grid item pl={2}>
          <ItemList
            groupIndex={props.groupIndex}
            isNew={props.isNew}
          ></ItemList>
          <Grid container direction={"column"} alignItems="flex-end">
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(addNewItem({ groupIndex: props.groupIndex }));
              }}
              sx={{ marginTop: 2, width: "200px" }}
            >
              New item
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {deleteVisible && (
        <ConfirmDialog
          open={deleteVisible}
          onClose={hideDeleteModal}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </Item>
  );
};

interface ItemListProps {
  groupIndex: number;
  isNew: boolean;
}

const ItemList = React.memo((props: ItemListProps) => {
  const itemsLength = useSelector(
    (state: any) =>
      state.templateEditor.template.groups?.[props.groupIndex].items?.length ||
      0
  );

  const items = useMemo(() => {
    return Array.from({ length: itemsLength }, (_, i) => i);
  }, [itemsLength]);

  return (
    <>
      {items.map((item: any) => {
        return (
          <TplEditorItem
            isNew={props.isNew}
            key={item + "_item"}
            groupIndex={props.groupIndex}
            itemIndex={item}
          ></TplEditorItem>
        );
      })}
    </>
  );
});

export default TplEditorGroup;
