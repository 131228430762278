import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataType } from "models/response/datatype";
import { TemplateGroup } from "models/response/templateGroup";
import {
  RegexValidation,
  TplEditData,
  TplEditDataGroup,
  TplEditDataItem,
  ValidationRules,
} from "models/response/tpleditdata";

export interface TemplateEditorState {
  template: TplEditData;
  attachmentsToRemove: string[];
  dataTypes: DataType[];
  templateGroupList: TemplateGroup[]; 
}

const initialState: TemplateEditorState = {
  template: {
    template_id: "",
    name: "",
    description: "",
    groups: [] as TplEditDataGroup[],
    group_id: "",
  } as TplEditData,
  templateGroupList: [] as TemplateGroup[],
  attachmentsToRemove: [] as string[],
  dataTypes: [] as DataType[],
};

const getItem = (
  state: TemplateEditorState,
  groupIndex: number,
  itemIndex: number
) => state.template.groups[groupIndex].items[itemIndex];

function initValidationRules(
  item: TplEditDataItem
): asserts item is TplEditDataItem & { validation_rules: ValidationRules } {
  if (!item.validation_rules) {
    item.validation_rules = {};
  }
  if (!item.validation_rules.regex) {
    item.validation_rules.regex = {} as RegexValidation;
  }
}
const templateEditorSlice = createSlice({
  name: "templateEditor",
  initialState,
  reducers: {
    setCurrentTemplate(state, action: PayloadAction<TplEditData>) {
      state.template = action.payload;
    },
    changeTemplateName(state, action: PayloadAction<any>) {
      state.template.name = action.payload;
    },
    changeTemplateDescription(state, action: PayloadAction<any>) {
      state.template.description = action.payload;
    },
    addInstructionAttachments(state, action: PayloadAction<any>) {
      const { attachments, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (item.instruction) {
        item.instruction.attachments = [
          ...item.instruction.attachments,
          ...attachments,
        ];
      }
    },
    deleteInstructionAttachment(state, action: PayloadAction<any>) {
      const { attachment, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (item.instruction) {
        item.instruction.attachments = item.instruction.attachments?.filter?.(
          (att) => att.attachment_id !== attachment.attachment_id
        );
        state.attachmentsToRemove.push(attachment.attachment_id);
      }
    },
    setInstructionText(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (!item.instruction) {
        item.instruction = {
          instruction_id: "",
          html: "",
          text: "",
          attachments: [],
        };
      }
      item.instruction.text = value;
    },
    setInstructionHtml(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (!item.instruction) {
        item.instruction = {
          instruction_id: "",
          html: "",
          text: "",
          attachments: [],
        };
      }
      item.instruction.html = value;
    },
    setItemName(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      state.template.groups[groupIndex].items[itemIndex].name = value;
    },
    setItemSortOrder(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      item.sort_order = value;
      item.alias = `[Q${value}]`;
    },
    setItemStoreGPS(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      item.store_gps = value;
    },
    setItemDescription(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      state.template.groups[groupIndex].items[itemIndex].description = value;
    },
    setItemDataType(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      state.template.groups[groupIndex].items[itemIndex].data_type = value;
    },
    addNewItem(state, action: PayloadAction<any>) {
      const { groupIndex } = action.payload;
      state.template.groups[groupIndex].items.push({});
    },
    addNewGroup(state, action: PayloadAction<any>) {
      state.template.groups.push({ items: [] });
    },
    changeValidationRules(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      Object.keys(value).forEach((key) => {
        if (value[key] === null) {
          // @ts-ignore
          delete item.validation_rules[key];
        } else {
          // @ts-ignore
          item.validation_rules[key] = value[key];
        }
      });
    },
    setGroupName(state, action: PayloadAction<any>) {
      const { value, groupIndex } = action.payload;
      state.template.groups[groupIndex].name = value;
    },
    setGroupSortOrder(state, action: PayloadAction<any>) {
      const { value, groupIndex } = action.payload;
      state.template.groups[groupIndex].sort_order = value;
    },
    setDataTypes(state, action: PayloadAction<DataType[]>) {
      state.dataTypes = action.payload;
    },
    setDisplayCondition(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      state.template.groups[groupIndex].items[itemIndex].display_condition =
        value;
    },
    setIntegrationKey(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      state.template.groups[groupIndex].items[itemIndex].integration_key =
        value;
    },
    setOCR(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (value) {
        item.ocr_settings = { is_enabled: value };
      } else {
        delete item.ocr_settings;
        if (item.input_source?.includes?.("OCR")) {
          item.input_source = item.input_source?.filter?.(
            (source) => source !== "OCR"
          );
        }
      }

      if (!item.input_source) {
        item.input_source = [];
      }
      if (!item.input_source.includes("OCR") && !!value) {
        item.input_source.push("OCR");
      }
    },
    setOCRSource(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (!item.ocr_settings) {
        item.ocr_settings = { is_enabled: true, source: value };
      } else {
        item.ocr_settings.source = value;
      }
    },
    setBarcode(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (value) {
        if (!item.input_source) {
          item.input_source = [];
        }
        if (!item.input_source.includes("B")) {
          item.input_source.push("B");
        }
      } else {
        item.input_source = item.input_source?.filter?.(
          (source) => source !== "B"
        );
      }
    },
    deleteItem(state, action: PayloadAction<any>) {
      const { itemIndex, groupIndex } = action.payload;
      state.template.groups[groupIndex].items.splice(itemIndex, 1);
    },
    addPicklistOption(state, action: PayloadAction<any>) {
      const { itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (!item.picklist_options) {
        item.picklist_options = [];
      }
      item.picklist_options.push({ display_value: "", alias: "" });
    },
    deletePicklistOption(state, action: PayloadAction<any>) {
      const { itemIndex, groupIndex, optionIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      item.picklist_options?.splice(optionIndex, 1);
    },
    changePicklistOption(state, action: PayloadAction<any>) {
      const { itemIndex, groupIndex, optionIndex, value } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (item.picklist_options) {
        item.picklist_options[optionIndex] = {
          ...item.picklist_options[optionIndex],
          ...value,
        };
      }
    },
    addNewReplacement(state, action: PayloadAction<any>) {
      const { itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      if (!item.validation_rules?.transforms) {
        item.validation_rules.transforms = {};
      }
      if (!item.validation_rules.transforms.replacements) {
        item.validation_rules.transforms.replacements = {};
      }
      if (item.validation_rules?.transforms?.replacements) {
        item.validation_rules.transforms.replacements[""] = "";
      }
    },
    deleteReplacement(state, action: PayloadAction<any>) {
      const { key, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (item.validation_rules?.transforms?.replacements) {
        delete item.validation_rules.transforms.replacements[key];
      }
    },
    changeReplacementKey(state, action: PayloadAction<any>) {
      const { key, newKey, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (item.validation_rules?.transforms?.replacements) {
        item.validation_rules.transforms.replacements[newKey] =
          item.validation_rules.transforms.replacements[key];
        delete item.validation_rules.transforms.replacements[key];
      }
    },
    changeReplacementValue(state, action: PayloadAction<any>) {
      const { key, value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      if (item.validation_rules?.transforms?.replacements) {
        item.validation_rules.transforms.replacements[key] = value;
      }
    },
    changeReplacementUppercase(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      if (!item.validation_rules.transforms) {
        item.validation_rules.transforms = {};
      }
      if (value) {
        item.validation_rules.transforms.uppercase = true;
      } else {
        delete item.validation_rules.transforms.uppercase;
      }
    },
    changeMinMaxValue(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex, field } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      (item.validation_rules as any)[field] = value;
    },
    changeRegexType(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      if (value === "none") {
        delete item.validation_rules.regex;
      } else {
        if (!item.validation_rules.regex) {
          item.validation_rules.regex = { type: value };
        } else {
          item.validation_rules.regex.type = value;
        }
      }
    },
    changeRegexExpression(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      item.validation_rules.regex!.exp = btoa(value);
    },
    changeRegexExample(state, action: PayloadAction<any>) {
      const { value, itemIndex, groupIndex } = action.payload;
      const item = getItem(state, groupIndex, itemIndex);
      initValidationRules(item);
      item.validation_rules.regex!.example = value;
    },
    deleteGroup(state, action: PayloadAction<any>) {
      const { groupIndex } = action.payload;
      state.template.groups.splice(groupIndex, 1);
    },
    setTemplateGroupList(state, action: PayloadAction<any>) {
      state.templateGroupList = action.payload;
    },
    changeTemplateGroup(state, action: PayloadAction<any>) {
      const { value } = action.payload;
      state.template.group_id = value;
    },
    clearTemplateEditor(state) {
      return initialState;
    },
  },
});

export const {
  setCurrentTemplate,
  changeTemplateName,
  changeTemplateDescription,
  addInstructionAttachments,
  deleteInstructionAttachment,
  setInstructionText,
  setInstructionHtml,
  setItemName,
  setItemSortOrder,
  setItemStoreGPS,
  setItemDescription,
  addNewItem,
  setDataTypes,
  setItemDataType,
  changeValidationRules,
  setGroupName,
  setGroupSortOrder,
  setDisplayCondition,
  setIntegrationKey,
  addNewGroup,
  setOCR,
  setOCRSource,
  setBarcode,
  deleteItem,
  addPicklistOption,
  deletePicklistOption,
  changePicklistOption,
  addNewReplacement,
  deleteReplacement,
  changeReplacementKey,
  changeReplacementValue,
  changeReplacementUppercase,
  changeMinMaxValue,
  changeRegexType,
  changeRegexExpression,
  changeRegexExample,
  deleteGroup,
  clearTemplateEditor,
  setTemplateGroupList,
  changeTemplateGroup,
} = templateEditorSlice.actions;
export default templateEditorSlice.reducer;
