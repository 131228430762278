import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import {
  TplEditDataInstruction,
  TplEditDataInstructionAttachment,
} from "models/response/tpleditdata";
import { useTplEditorStyles } from "./styles";
import { useMemo, useRef, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import {
  addInstructionAttachments,
  deleteInstructionAttachment,
  setInstructionHtml,
  setInstructionText,
} from "redux/reducers/templateEditorSlice";

export interface TplEditorInstructionProps {
  instruction: TplEditDataInstruction;
  itemIndex: number;
  groupIndex: number;
  isNew: boolean;
}

const TplEditorInstruction = (props: TplEditorInstructionProps) => {
  const { classes } = useTplEditorStyles();

  const dispatch = useDispatch();

  const [instructionAttachmentsVisible, setInstructionAttachmentsVisible] =
    useState(false);
  const toggleInstructionAttachments = (e: any) => {
    setInstructionAttachmentsVisible((prev) => !prev);
  };
  const [exampleAttachmentsVisible, setExampleAttachmentsVisible] =
    useState(false);
  const toggleExampleAttachments = (e: any) => {
    setExampleAttachmentsVisible((prev) => !prev);
  };

  const hideAttachmentModals = () => {
    setInstructionAttachmentsVisible(false);
    setExampleAttachmentsVisible(false);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const openFilePickerIfKeyValid = (e: any) => {
    if (instructionAttachmentsVisible && !htmlKey) {
      alert("Please enter an HTML key for the attachment");
    } else if (
      instructionAttachmentsVisible &&
      htmlAttachmentList?.find((att) => att.html_key === htmlKey)
    ) {
      alert("HTML key already exists. Please enter a different key");
    } else {
      fileInputRef?.current?.click();
    }
  };

  const instructionText = useSelector(
    (state: any) =>
      state.templateEditor.template.groups[props.groupIndex].items[
        props.itemIndex
      ].instruction.text
  );
  const handleInstructionTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      setInstructionText({
        value: e.target.value,
        itemIndex: props.itemIndex,
        groupIndex: props.groupIndex,
      })
    );
  };

  const instructionHtml = useSelector(
    (state: any) =>
      state.templateEditor.template.groups[props.groupIndex].items[
        props.itemIndex
      ].instruction.html
  );
  const handleInstructionHtmlChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      setInstructionHtml({
        value: e.target.value,
        itemIndex: props.itemIndex,
        groupIndex: props.groupIndex,
      })
    );
  };

  const handleNewFilesAdded = (e: any) => {
    let filesToAdd: TplEditDataInstructionAttachment[] =
      [] as TplEditDataInstructionAttachment[];
    for (let i = 0; i < e.target.files.length; i++) {
      let fileToAdd = e.target.files[i];
      let url = URL.createObjectURL(fileToAdd);
      filesToAdd.push({
        attachment_id: undefined,
        file_name: fileToAdd.name,
        url: url,
        thumbnail_url: url,
        file_obj: fileToAdd,
        html_key: htmlKey,
      } as TplEditDataInstructionAttachment);
    }
    dispatch(
      addInstructionAttachments({
        attachments: filesToAdd,
        itemIndex: props.itemIndex,
        groupIndex: props.groupIndex,
      })
    );
    setHtmlKey("");
  };

  const handleDeleteAttachment = (
    attachment: TplEditDataInstructionAttachment
  ) => {
    dispatch(
      deleteInstructionAttachment({
        attachment,
        itemIndex: props.itemIndex,
        groupIndex: props.groupIndex,
      })
    );
  };

  const htmlAttachmentList = useMemo(() => {
    return props.instruction.attachments?.filter((att) => att.html_key);
  }, [props.instruction.attachments]);

  const exampleAttachmentList = useMemo(() => {
    return props.instruction.attachments?.filter((att) => !att.html_key);
  }, [props.instruction.attachments]);

  const [htmlKey, setHtmlKey] = useState("");
  const handleHtmlKeyChange = (e: any) => {
    setHtmlKey(e.target.value);
  };

  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Grid container direction={"row"}>
          <TextField
            multiline
            size="small"
            rows={4}
            label="Instruction text"
            value={instructionText}
            className={classes.editorInputFullWidth}
            onChange={handleInstructionTextChange}
            sx={{ ml: 1 }}
          ></TextField>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container mt={2} mb={2}>
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={exampleAttachmentList?.length}
          >
            <Button
              variant="outlined"
              component="label"
              onClick={toggleExampleAttachments}
              sx={{ mr: 3.4 }}
            >
              Example Attachments
            </Button>
          </Badge>
          <Badge
            color="primary"
            overlap="circular"
            badgeContent={htmlAttachmentList?.length}
          >
            <Button
              variant="outlined"
              component="label"
              onClick={toggleInstructionAttachments}
              sx={{ mr: 3 }}
            >
              HTML Instructions
            </Button>
          </Badge>

          {(exampleAttachmentsVisible || instructionAttachmentsVisible) && (
            <Dialog
              open={exampleAttachmentsVisible || instructionAttachmentsVisible}
            >
              {exampleAttachmentsVisible && (
                <DialogTitle>Example Attachments</DialogTitle>
              )}
              {instructionAttachmentsVisible && (
                <DialogTitle>HTML Instructions</DialogTitle>
              )}
              <IconButton
                aria-label="close"
                onClick={hideAttachmentModals}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <GridCloseIcon />
              </IconButton>

              {instructionAttachmentsVisible && (
                <Box mx={3}>
                  <TextField
                    autoFocus
                    multiline
                    size="small"
                    rows={20}
                    label="HTML instructions"
                    value={instructionHtml}
                    style={{ minWidth: 500, width: "100%" }}
                    onChange={handleInstructionHtmlChange}
                  ></TextField>
                </Box>
              )}
              <DialogContent>
                <Box display="flex" alignItems="center">
                  {instructionAttachmentsVisible && (
                    <TextField
                      size="small"
                      fullWidth
                      label="HTML key"
                      value={htmlKey}
                      onChange={handleHtmlKeyChange}
                      sx={{ flexGrow: 1, mr: 2 }}
                    />
                  )}
                  <Button
                    variant="outlined"
                    component="label"
                    onClick={openFilePickerIfKeyValid}
                    sx={{ mb: 2.5, width: 300, mt: 1.8 }}
                  >
                    Add attachment
                  </Button>
                </Box>
                <input
                  type="file"
                  hidden
                  multiple={exampleAttachmentsVisible}
                  ref={fileInputRef}
                  onChange={(e) => handleNewFilesAdded(e)}
                />
                {props.instruction.attachments &&
                  props.instruction.attachments.length > 0 && (
                    <Grid item>
                      <Grid container direction={"row"}>
                        <ImageList
                          sx={{ width: 500, minHeight: 180, maxHeight: 450 }}
                          cols={3}
                          rowHeight={163}
                        >
                          {(instructionAttachmentsVisible
                            ? htmlAttachmentList
                            : exampleAttachmentList
                          )?.map((att, index) => {
                            return (
                              <Box
                                border={1}
                                borderColor={"#ddd"}
                                key={`${att.html_key}${index}`}
                              >
                                <ImageListItem key={att.url}>
                                  <img
                                    height={250}
                                    width={250}
                                    src={`${att.thumbnail_url}`}
                                    alt={att.file_name}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar
                                    sx={{
                                      background:
                                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                    }}
                                    title={att.file_name}
                                    position="top"
                                    actionIcon={
                                      <IconButton
                                        component="button"
                                        onClick={() => {
                                          handleDeleteAttachment(att);
                                        }}
                                        sx={{ color: "white" }}
                                      >
                                        <DeleteForever />
                                      </IconButton>
                                    }
                                    actionPosition="left"
                                  />
                                </ImageListItem>
                                {instructionAttachmentsVisible && (
                                  <Typography
                                    align="center"
                                    mt={1}
                                    bgcolor={"#ddd"}
                                  >
                                    {att.html_key || "no key defined"}
                                  </Typography>
                                )}
                              </Box>
                            );
                          })}
                        </ImageList>
                      </Grid>
                    </Grid>
                  )}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="contained"
                    component="label"
                    onClick={hideAttachmentModals}
                  >
                    Done
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TplEditorInstruction;
