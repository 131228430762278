import { Box, Button, Grid, Typography } from "@mui/material";
import { TplEditData } from "models/response/tpleditdata/tpleditdata";
import { TplEditDataInstruction } from "models/response/tpleditdata";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  deleteSurveyItemInstructionAttachment,
  saveOrEditSurveyTemplate,
  getSurveyTemplateEditData,
  getSurveyTemplateItemDataTypes,
  uploadSurveyItemInstructionAttachment,
  getTemplateGroups,
} from "services/surveytemplates";
import TplEditorTemplate from "./TplEditorTemplate";
import { useAuthData } from "utils/hooks/useAuthData";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTemplateEditor,
  setCurrentTemplate,
  setDataTypes,
  setTemplateGroupList,
} from "redux/reducers/templateEditorSlice";
import { RootState } from "redux/store";
import { trackPromise } from "react-promise-tracker";

const SurveyTemplateEditor = () => {
  let { templateId } = useParams();
  const { getRawIdToken, ready } = useAuthData();
  const { enqueueSnackbar } = useSnackbar();
  const [isNew, setIsNew] = useState<boolean | undefined>(undefined);
  const [templateName, setTemplateName] = useState<string>("");
  const dispatch = useDispatch();

  const template = useSelector(
    (state: RootState) => state.templateEditor.template
  );

  useEffect(() => {
    const fetchCommonData = (surveyData?: boolean) => {
      return trackPromise(
        (async () => {
          const idToken = await getRawIdToken();
          const dataTypesResponse = await getSurveyTemplateItemDataTypes(
            idToken
          );
          const templateGroupsResponse = await getTemplateGroups(idToken);
          if (dataTypesResponse?.result) {
            dispatch(setDataTypes(dataTypesResponse.result));
          }
          if (templateGroupsResponse?.result) {
            dispatch(setTemplateGroupList(templateGroupsResponse.result));
          }

          if (surveyData) {
            const surveyTemplate = await getSurveyTemplateEditData(
              idToken,
              templateId
            );
            if (surveyTemplate && surveyTemplate.result) {
              prepareSurveyTemplateForEditing(surveyTemplate.result);
              dispatch(setCurrentTemplate(surveyTemplate.result));
              setTemplateName(surveyTemplate.result.name);
            }
          }
        })()
      );
    };

    if (isNew) {
      console.log("is new");
    } else if (templateId && ready && isNew === undefined) {
      setIsNew(false);
      fetchCommonData(true);
    } else if (isNew === undefined) {
      dispatch(clearTemplateEditor());
      fetchCommonData();
      setIsNew(true);
    }
  }, [templateId, ready, isNew]);

  const prepareSurveyTemplateForEditing = (tpl: TplEditData) => {
    tpl.groups.forEach((group) => {
      group.items.forEach((item) => {
        if (!item.instruction) {
          item.instruction = {
            text: "",
            html: "",
            attachments: [],
            instruction_id: undefined,
          } as TplEditDataInstruction;
        }
      });
    });
  };

  const attachmentsToRemove = useSelector(
    (state: RootState) => state.templateEditor.attachmentsToRemove
  );
  const handleSurveyTemplateSave = async () => {
    const idToken = await getRawIdToken();

    const saveResult = await saveOrEditSurveyTemplate(
      idToken,
      template.template_id,
      template.version,
      template
    );
    if (saveResult && saveResult.result) {
      for (const group of template.groups) {
        for (const item of group.items) {
          if (item.instruction && item.instruction.attachments) {
            for (const att of item.instruction.attachments) {
              if (!att.attachment_id) {
                await uploadSurveyItemInstructionAttachment(
                  idToken,
                  templateId,
                  saveResult.result.version,
                  item.item_id,
                  att.file_obj,
                  att.html_key
                );
              }
            }
          }
        }
      }

      for (const attId of attachmentsToRemove) {
        await deleteSurveyItemInstructionAttachment(
          idToken,
          attId,
          saveResult.result.version
        );
      }
      enqueueSnackbar("Template updated!", {
        variant: "success",
      });
    }
  };
  return (
    <Grid container direction="column" justifyContent="center">
      <Grid item overflow="auto" height="calc(100vh - 80px)">
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={6}>
            {!isNew && templateName && (
              <Typography sx={{ textAlign: "center", mb: 3 }} variant="h4">
                Editing{" "}
                <Box sx={{ fontWeight: "bold" }} display="inline">
                  {templateName}
                </Box>{" "}
                v{template?.version}
              </Typography>
            )}
            {isNew && (
              <Typography sx={{ textAlign: "center", mb: 3 }} variant="h4">
                New Template
              </Typography>
            )}
          </Grid>

          <Grid container direction="row" justifyContent="flex-end" mt={"10px"}>
            <Button
              variant="outlined"
              sx={{ mr: 1 }}
              component={Link}
              to="../templates"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSurveyTemplateSave}>
              Save
            </Button>
          </Grid>
        </Grid>
        <TplEditorTemplate isNew={!!isNew} />
      </Grid>
    </Grid>
  );
};
export default SurveyTemplateEditor;
