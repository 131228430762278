import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import urljoin from "url-join";
import { ApiResponse } from "./models/apiresponse";

import { enqueueSnackbar } from "notistack";
import { trackPromise } from "react-promise-tracker";
export const callapi = async (options: {
  config: AxiosRequestConfig;
}): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse = await axios(options.config);
    const { data } = response;
    return {
      data,
    };
  } catch (error) {
    let message: string = "http request failed";
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const { response } = axiosError;
      if (response && response.data && (response.data as any).result) {
        message = (response.data as any).result.description;
      } else if (axiosError.message) {
        message = axiosError.message;
      }
    }

    enqueueSnackbar(message, { variant: "error" });
    return {
      data: null,
    };
  }
};
export const trackedApiCall = (config: AxiosRequestConfig) =>
  trackPromise(callapi({ config }));
export const setCallConfig = (
  path: string,
  method: string,
  token: string | undefined,
  data: any | undefined = null
) => {
  const config: AxiosRequestConfig = {
    url: urljoin(`${window.APP_CONFIG.TECHNICIAN_API_DOMAIN}`, `${path}`),
    method: `${method}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return config;
};
export const setFormDataConfig = (
  path: string,
  token: string | undefined,
  formData: FormData
) => {
  const config: AxiosRequestConfig = {
    url: urljoin(`${window.APP_CONFIG.TECHNICIAN_API_DOMAIN}`, `${path}`),
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };
  return config;
};
